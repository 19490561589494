<script setup lang="ts">
import { mapSize, mapWeight } from '~/components/UI/DesignSystem/utils'

const props = defineProps<{
  size?: string
  weight?: string
  muted?: boolean
}>()
const size = mapSize(props.size)
const weight = mapWeight(props.weight)

const classes = computed(() => {
  return {
    [`text-${size}`]: true,
    [`font-${weight.toString()}`]: true,
    ['text-color-gray-500']: props.muted,
    ['text-color-gray-900']: !props.muted,
  }
})
</script>

<template>
  <p :class="classes">
    <slot></slot>
  </p>
</template>
