<script setup lang="ts">
import { getQueryParam } from '~/utils/route'

/**
 * @modelValue : Number
 * (The selected Tab)
 *
 * @listTabs : Array<String>
 * (This list will render the defined tabs, don't forget to add localisation)
 *
 * @tabName : String
 * (The name of the query param to use for the tab)
 */

const props = defineProps({
  modelValue: {
    type: Number,
    required: true,
    default: 1,
  },
  listTabs: {
    type: Array<string>,
    required: true,
    default: [] as string[],
  },
  tabName: {
    type: String,
    required: false,
    default: 'tab',
  },
  class: {
    type: String,
    required: false,
    default: '',
  },
})

const emit = defineEmits<{
  (e: 'update:modelValue', val: number): void
}>()

const router = useRouter()
const route = useRoute()

function setCurrentTab(index: number) {
  if (props.tabName != 'instance-modal-tabs') {
    router.replace({
      query: {
        ...route.query,
        [props.tabName]: index,
      },
    })
  }

  emit('update:modelValue', index)
}

function init() {
  const param = props.modelValue ?? Number.parseInt(getQueryParam(props.tabName))
  emit('update:modelValue', param ?? 0)
}
onMounted(init)
</script>

<template>
  <nav class="flex gap-6 overflow-x-auto" :class="props.class">
    <template v-for="(tab, index) in props.listTabs" :key="index">
      <button
        class="group pb-2 text-sm cursor-pointer whitespace-nowrap"
        :class="[
          props.modelValue === index
            ? 'text-secondary font-medium border-b-2 border-[#373FEF]'
            : 'border-transparent text-[#475467] hover:text-[#373FEF] border-b-2 hover:border-gray-300',
        ]"
        @click="setCurrentTab(index)"
      >
        <slot :name="index" :tab="tab">
          {{ tab }}
        </slot>
      </button>
    </template>
  </nav>
</template>
