import Echo from 'laravel-echo'
import { defineStore } from 'pinia'
import { userStore } from '~/stores/user'
import type { EntitySchema } from '~/types/view-elements'
import { apiStore } from './api'
import { useSockets } from './sockets'

export const ENTITY_TYPES_CHANNEL = 'Knowledge.EntityTypes'

export enum EntityTypesEvents {
  CREATED = '.knowledge.entity-type.created',
  UPDATED = '.knowledge.entity-type.updated',
  DELETED = '.knowledge.entity-type.deleted',
}

interface State {
  entity_types: {
    list: EntitySchema[]
    canCreate: boolean
  }
  loaded: boolean
  socket: Echo | null
}

export const entityTypesStore = defineStore({
  id: 'entity_types',
  state: (): State => ({
    entity_types: {
      list: [],
      canCreate: false,
    },
    loaded: false,
    socket: null,
  }),
  getters: {
    getEntityTypes(): EntitySchema[] {
      return this.entity_types.list
    },
    getEntityTypesAsOptions(): EntitySchema[] {
      return this.entity_types.list.map(i => {
        return {
          ...i,
          value: i.id,
          label: i.name,
        }
      })
    },
    getEntityTypesAsOptionsWithNameAsValue(): EntitySchema[] {
      return this.entity_types.list.map(i => {
        return {
          ...i,
          value: i.name,
          label: i.display_name || i.name,
        }
      })
    },
    getSocketClient: async (state): Promise<Echo> => {
      if (state.socket) return state.socket

      const sockets = useSockets()
      state.socket = await sockets.getClient()
      return state.socket
    },
  },
  actions: {
    getEntityType(entity_typeId: string): EntitySchema | undefined {
      return this.entity_types.list.find(item => item.id === entity_typeId)
    },

    getEntityTypeByName(name: string): EntitySchema | undefined {
      return this.entity_types.list.find(item => item.name === name)
    },

    async reload() {
      this.loaded = false
      return this.loadEntityTypes()
    },

    async loadEntityTypes(): Promise<void> {
      const user = userStore().user
      if (!user || this.loaded) return

      const api = apiStore().api
      const { data, auth } = await api.getEntityTypes()
      this.entity_types.list = data
      this.entity_types.canCreate = Boolean(auth.can.create)
      this.loaded = true

      this.subscribe()
    },

    async subscribe() {
      const client = await this.getSocketClient

      client
        .private(ENTITY_TYPES_CHANNEL)
        .listen(EntityTypesEvents.CREATED, async (entityType: EntitySchema) => {
          const { data } = await apiStore().api.getEntityType(entityType.id)
          this.entity_types.list.push(data)
        })
        .listen(EntityTypesEvents.UPDATED, async (entityType: EntitySchema) => {
          const { data } = await apiStore().api.getEntityType(entityType.id)
          const index = this.entity_types.list.findIndex(schema => schema.id === entityType.id)
          if (index !== -1) {
            this.entity_types.list[index] = data
          }
        })
        .listen(EntityTypesEvents.DELETED, ({ id }: { id: string }) => {
          const index = this.entity_types.list.findIndex(schema => schema.id === id)
          if (index !== -1) {
            this.entity_types.list[index] = {
              ...this.entity_types.list[index],
              deleted_at: new Date().toISOString(),
            }
          }
        })
    },
    async unsubscribe() {
      const client = await this.getSocketClient

      client.leave(ENTITY_TYPES_CHANNEL)
    },
  },
})
